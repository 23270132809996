<template>
    <div>
        <div class="row row-sm mg-t-20 mg-b-20">
            <div class="col-9">
                <p v-html="$DEFINITIONS.didDocument"></p>
            </div>
            <div class="col-3">
                <router-link :to="{ name: 'addrecords', params: { did: this.did } }"
                    class="d-none d-xl-block btn btn-orange float-right rounded font-weight-bold" title="Add DID">
                    <i class="fas fa-plus-square mg-r-10" />Add Record
                </router-link>
                <router-link :to="{ name: 'addrecords', params: { did: this.did } }"
                    class="d-xl-none w-50 btn btn-icon btn-orange float-right rounded" title="Add DID">
                    <i class="typcn typcn-document-add" />
                </router-link>
            </div>
        </div>
        <data-table :api="api" :columns="columns" :server-side="false" @ready="onReady" list-key="did_attachment_list"
            table-id="attachment_table" @resJson="resData"></data-table>

        <delete-modal :delete-function="removeAttachment"
            :message="'Are you sure you want to remove the Attachment from this DID?'" :service="'identity'" :table="table"
            :url="`identity/dids/${did}/documents/${attachmentIdToRemove}`" v-if="attachmentIdToRemove"></delete-modal>
    </div>
</template>

<script>
import EventBus from "@/event-bus";

export default {
    name: "DidAttachments",
    props: ['catalogid', 'catalogNameProp', 'did', 'short_nameProp'],
    data() {
        return {
            catalogName: this.catalogNameProp,
            short_name: this.short_nameProp,
            api: `${this.$identity.defaults.baseURL}/identity/dids/${this.did}/documents`,
            columns: [
                { title: 'Name', data: 'property_name' },
                { title: 'File Name', data: 'name' },
                { title: 'File Hash', data: 'hash', render: data => this.$options.filters.hex_format(data, 15, '...') },
                { type: 'created' },
                {
                    type: 'action',
                    defaultContent: `
                          <div class="btn-group btn-icon-list">
                                ${this.$StandardButtons.deleteButton('#confirmationmodal', 'Remove from DID', 'Remove')}
                                <span class="text-decoration-none text-info download mg-l-10" style="cursor: pointer">
                                    <i class="fas fa-download fa-lg" title="Download attachment"></i>
                                </span>
                          </div>`
                },
            ],
            table: null,
            attachmentIdToRemove: null
        };
    },
    methods: {
        resData(json) {
            this.$emit('attach',json.total);
        },
        onReady(table) {
            console.log(table)
            this.table = table;
            let self = this;

            $('#attachment_table tbody').on('click', '.delete', function () {
                let entity = table.row($(this).parents('tr')).data();
                self.setAttachmentToRemove(entity);
                table.ajax.reload();
            });

            $('#attachment_table tbody').on('click', '.download', function () {
                let entity = table.row($(this).parents('tr')).data();
                self.downloadAttachment(entity);
                table.ajax.reload();
            });

        },
        setAttachmentToRemove(attachment) {
            this.attachmentIdToRemove = attachment.id;
        },
        async downloadAttachment(attachment) {
            let { data } = await this.$identity.get(`identity/dids/${attachment.did}/documents/${attachment.id}`);
            let link = document.createElement('a');
            link.href = 'data:application/octet-stream;base64,' + data;
            link.download = attachment.name;
            link.click();
        },
        async removeAttachment() {
            try {
                EventBus.$emit('openLoader');
                await this.$identity.delete(`identity/dids/${this.did}/documents/${this.attachmentIdToRemove}`);
                this.$toastr.s("Attachment removed from DID.", 'Success');
                this.attachmentIdToRemove = null;
            } catch (e) {

            } finally {
                EventBus.$emit('closeLoader');
                this.table.ajax.reload();
            }
        }
    }
}
</script>

<style scoped></style>