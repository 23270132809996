 <template>
  <td
    :title="did"
    class="masked"
  >
    <a
      href="javascript:void(0);"
      v-clipboard:copy="did"
      v-clipboard:success="copied"
      @click.stop
    >
      {{did | truncate(10, '......')}}
    </a>
  </td>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "did-link",
  props: ['did'],

  methods: {
    copied() {
      this.$toastr.i("Copied to clipboard", "Copied");
    }
  }
};
</script>