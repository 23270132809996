<template>
    <standard-page :definition="$DEFINITIONS.did" sub_title="Decentralized Identifier"
        v-bind:title="did_response.short_name">
        <template v-slot:breadcrumb>

            <span>
                <router-link :to="{ name: 'identities-home' }">Identifier</router-link>
            </span>
            <span>
                <router-link :to="{ name: 'identities-catalogs' }">Catalogs</router-link>
            </span>
            <span v-if="catalogName">
                <router-link :to="{ name: 'identities-catalogs-dids' }">CATALOG: {{ catalogName | truncate(20,
                    '.....') }}</router-link>
            </span>
            <span v-if="did_response">DID: {{ did_response.short_name | truncate(20, '.....') }}

            </span>


        </template>
        <template v-slot:buttons>
            <div :style="{ 'display': 'flex', 'justify-content': 'flex-end' }">
                <button @click="exportDidToCSV()" class="btn btn-info btn-icon rounded" :style="{ 'align': 'right' }">
                    <i class="typcn typcn-export-outline"></i>
                </button>
            </div>
        </template>

        <template v-slot:sub-title>
            <p class="mg-b-10">
                <a href="javascript:void(0);" v-clipboard:copy="did" v-clipboard:success="copied">
                    {{ did | did }}
                </a>
            </p>
        </template>

        <template v-slot:content>

            <div class="az-content-body az-content-body-profile">
                <nav class="nav az-nav-line">
                    <a :class="{ active: activeTab === 0 }" @click="navigate(0)" class="nav-link" data-toggle="tab"
                        href="#account">DID Record</a>
                    <a :class="{ active: activeTab === 1 }" @click="navigate(1)" class="nav-link" data-toggle="tab"
                        href="#did_attachment">DID Attachments</a>
                    <a :class="{ active: activeTab === 2 }" @click="navigate(2)" class="nav-link" data-toggle="tab"
                        href="#wallet">Claims</a>
                    <a :class="{ active: activeTab === 3 }" @click="navigate(3)" class="nav-link" data-toggle="tab"
                        href="#didcontroller">DID Controllers</a>
                    <a :class="{ active: activeTab === 4 }" @click="navigate(4)" class="nav-link" data-toggle="tab"
                        href="#authorization">DID Authorizations</a>
                </nav>
                <div class="az-tab-content" v-if="did_retrieved">
                    <div :class="{ 'd-none': activeTab !== 0 }" id="record">
                        <did-records :catalogNameProp="catalogName" :catalogid="catalogid" :did="did_response.did"
                            :short_nameProp="did_response.short_name" @records="resData" />
                    </div><!-- az-profile-body -->
                    <div :class="{ 'd-none': activeTab !== 1 }" id="did_attachment">
                        <did-attachments :catalogNameProp="catalogName" :catalogid="catalogid" :did="did_response.did"
                            :short_nameProp="did_response.short_name" @attach="attData" />
                    </div><!-- az-profile-body -->
                    <div :class="{ 'd-none': activeTab !== 2 }" id="claims">
                        <claims :catalogid="catalogid" :did="did" @claims="claimsData" />
                    </div><!-- az-profile-body -->
                    <div :class="{ 'd-none': activeTab !== 3 }" id="didcontroller">
                        <did-controller :catalogNameProp="catalogName" :controllers="did_response.controllers"
                            :did="did_response.did" :short_nameProp="did_response.short_name" @getDid="getDid"
                            v-if="did_response" />
                    </div><!-- az-profile-body -->
                    <div :class="{ 'd-none': activeTab !== 4 }" id="authorization">
                        <did-authorization :did="did" />
                    </div><!-- az-profile-body -->
                </div>
            </div>

        </template>
    </standard-page>
</template>

<script>
import DidRecords from "./didRecord/DidRecords";
import Claims from "./claim/Claims";
import DidController from "./DidController";
import DidAuthorization from "./DidAuthorization";
import DidAttachments from "./did_attachments/DidAttachments";
import XLSX from 'xlsx';

export default {
    name: "ViewDID",
    props: ['catalogid', 'did'],
    components: { DidAttachments, DidAuthorization, DidController, DidRecords, Claims },
    data() {
        return {
            catalogName: this.catalogNameProp,
            short_name: this.short_nameProp,
            activeTab: 0,
            did_response: { short_name: '' },
            did_retrieved: false,
            data_record: [],
            data_attach: [],
            data_claims: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (!this.catalogName) {
                await this.getCatalog();
            }
            await this.getDid();
        },
        async resData(value) {
            let { data } = await this.$identity.get(`identity/dids/${this.did_response.did}/properties?page=0&per_page=${value}`);

            let arr = [];
            arr = data.property_list.map(e => {
                return {
                    NAME: e.name,
                    VALUE: e.fact.value,
                    CREATED_ON: new Date(e.created).toLocaleDateString(),
                }
            });
            this.data_record = arr;
        },
        async attData(value) {
            let { data } = await this.$identity.get(`/identity/dids/${this.did_response.did}/documents?page=0&per_page=${value}`);

            let arr = []
            arr = data.did_attachment_list.map(e => {
                return {
                    NAME: e.property_name,
                    FILENAME: e.name,
                    CREATED_ON: new Date(e.created).toLocaleDateString(),
                }
            })

            this.data_attach = arr;
        },

        convertStatement(objArr) {
            let v = '';
            objArr.forEach(obj => {
                Object.entries(obj).forEach(e => {
                    v += `${e[0]}:${e[1]},  `
                });
            });
            return v.slice(0, -3);
        },

        async claimsData(value) {
            let { data } = await this.$identity.get(`/identity/dids/${this.did_response.did}/claims?page=0&per_page=${value}`);

            // statement array breakdown
            let stArr = [];

            for (let i = 0; i < value; i++) {
                let obj = {};
                let stmts = data.claims[i].statements;
                obj = stmts.map(e => {
                    return {
                        [e.name]: e.fact.value,
                    }
                });
                stArr = [...stArr, obj];
            }

            // claim arr
            let arr = [];
            arr = data.claims.map((e, i) => {
                return {
                    CLAIM: e.description,
                    STATEMENTS: this.convertStatement(stArr[i]),
                    ISSUED_ON: new Date(e.created).toLocaleDateString(),
                    STATUS: e.attested_by ? 'Attested' : 'Not Attested',
                }
            })

            this.data_claims = arr;
        },
        async getCatalog() {
            try {
                let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}`);
                this.catalogName = reply.data ? reply.data.name : null;
            } catch (e) {
                this.$toastr.e("Error getting catalog name", "Error");
            }
        },
        navigate(index) {
            this.activeTab = index;
        },
        copied() {
            this.$toastr.i("Copied to clipboard", "Copied");
        },
        async getDid() {
            try {
                let { data } = await this.$identity.get(`/identity/catalogs/${this.catalogid}/dids/${this.did}`);
                this.did_response = data;
                this.did_retrieved = true;
            } catch (e) {
                this.$toastr.e("Error retrieving DID", "Error");
            }
        },
        exportDidToCSV() {
            let fileName = this.did_response.did.slice(-5) + "_" + new Date().toLocaleDateString() + '.xlsx';

            //code for export 
            let workbook = XLSX.utils.book_new();
            let worksheet1 = null;
            let worksheet2 = null;
            let worksheet3 = null;

            worksheet1 = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(this.data_record)));
            worksheet2 = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(this.data_attach)));
            worksheet3 = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(this.data_claims)));

            XLSX.utils.book_append_sheet(workbook, worksheet1, 'DID_RECORDS');
            XLSX.utils.book_append_sheet(workbook, worksheet2, 'DID_ATTACHMENTS');
            XLSX.utils.book_append_sheet(workbook, worksheet3, 'CLAIMS');

            XLSX.writeFile(workbook, fileName);

        },
    }
}
</script>

<style scoped></style>