<template>
    <div>
        <p class="mg-b-10 mg-t-20" v-html="$DEFINITIONS.claim">
        </p>

        <data-table :api="api" :columns="columns" @ready="onReady" list-key="claims" tableId="claims-table"
            @resJson="resData"></data-table>

        <view-attestation-modal :catalogid="catalogid" :claimid="claim_id" :did="did"
            v-if="claim_id >= 0"></view-attestation-modal>
    </div>
</template>

<script>
import ViewAttestationModal from "./ViewAttestationModal";
import Pager from '@/components/Pager';
import ExtrinsicLink from '@/components/ExtrinsicLink';

export default {
    name: "Claims",
    components: { ViewAttestationModal, ExtrinsicLink, Pager },
    props: ['catalogid', 'did'],

    data() {
        return {
            api: `${this.$identity.defaults.baseURL}/identity/dids/${this.did}/claims`,
            columns: [
                { title: 'Claim', data: 'description' },
                {
                    type: 'statements',
                },
                { type: 'created', title: "Issued On" },
                { type: 'extrinsic' },
                { title: 'Status', data: 'current_attestations_count', render: data => data > 0 ? `<span class="attested">Attested</span>` : `<span class="notattested">Not attested</span>` },
                {
                    type: 'action',
                    defaultContent: `
                          <div class="btn-group btn-icon-list">
                                <span class="text-decoration-none text-info view_claim mg-l-10" style="cursor: pointer">
                                    <i class="fas fa-eye fa-lg" title="View Claim Attachments & Attestations"></i>
                                </span>
                          </div>`
                }
            ],
            table: null,
            claim_id: null,

        };
    },

    methods: {

        resData(json) {
            this.$emit('claims', json.total);
        },

        onReady(table) {
            this.table = table;
            let self = this;

            $(async function () {
                $('#claims-table tbody').on('click', '.view_claim', function () {
                    let entity = table.row($(this).parents('tr')).data();
                    self.$router.push({ name: 'view-claim', params: { claimid: entity.claim } });
                });

            });
        },


        setClaimId(claimid) {
            this.claim_id = claimid;
            this.$router.push({ name: 'view-attestation', params: { did: this.did, claimid: claimid } })
        },
    }
}
</script>

<style scoped></style>