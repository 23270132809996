<template>
    <p>{{short_name}}</p>
</template>

<script>
    export default {
        name: "DidName",
        props: ['did'],
        watch: {
            did: function (nv, ov) {
                if (nv) {
                    this.getDid();
                }
            }
        },
        data() {
            return {
                short_name: null
            }
        },
        mounted() {
            this.getDid();
        },
        methods : {
            async getDid() {
                try {
                    let {data} = await this.$identity.get(`/identity/dids/${this.did}`);
                    this.short_name = data ? data.short_name : null;
                } catch (e) {
                }
            }
        }
    }
</script>

<style scoped>

</style>