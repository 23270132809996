<template>
  <div>
    <div class="row row-sm mg-t-20 mg-b-20">
        <div class="col-9">
            <div class="az-content-label">Create Claims</div>
            <p v-html="$DEFINITIONS.claimConsumer"></p>
        </div>
        <div class="col-3">
            <router-link :to="{name: 'didauthorization', params: { did: this.did }}" class="d-none d-xl-block btn btn-orange float-right rounded font-weight-bold">
                <i class="fas fa-plus-square mg-r-10"/>Add
            </router-link>
            <router-link :to="{name: 'didauthorization', params: { did: this.did }}" class="d-xl-none btn btn-icon btn-orange float-right rounded w-50">
                <i class="typcn typcn-document-add"/>
            </router-link>
        </div>
    </div>

    <div class="table-responsive" v-if="consumers.length >0">
      <table class="table table-striped mg-b-0">
        <thead>
          <tr>
            <th class="tx-left">#</th>
            <th class="tx-left">DID</th>
              <th class="tx-left">Short Name</th>
            <th class="tx-left">Expiry</th>
            <th class="tx-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-bind:key="index" v-for="(c, index) in consumers">
            <td class="masked">{{index+1}}</td>
              <td class="masked"><did-link :did="c.did"></did-link></td>
              <td class="masked"><did-name :did="c.did"></did-name></td>
            <td class="masked">{{c.expiry | medium}}</td>
            <td>
              <span @click="setConsumerDidToRevoke(c.did)" class="text-danger delete" data-target="#controllerDeleteModal" data-toggle="modal" style="cursor: pointer">
                <i class="fas fa-trash-alt" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mg-t-50" v-else>
      <h4 class="card-title text-muted text-center">No records found</h4>
    </div>
    <!--        <hr>-->

      <div class="row row-sm mg-t-50 mg-b-20">
          <div class="col-9">
              <div class="az-content-label">Attest Claims</div>
              <p v-html="$DEFINITIONS.claimIssuer"></p>
          </div>
          <div class="col-3">
              <router-link :to="{name: 'didauthorization', params: { did: this.did }}" class="d-none d-xl-block btn btn-orange float-right rounded font-weight-bold">
                  <i class="fas fa-plus-square mg-r-10"/>Add
              </router-link>
              <router-link :to="{name: 'didauthorization', params: { did: this.did }}" class="d-xl-none btn btn-icon btn-orange float-right rounded w-50">
                  <i class="typcn typcn-document-add"/>
              </router-link>
          </div>
      </div>
    <div class="table-responsive" v-if="issuers.length >0">
      <table class="table table-striped mg-b-0">
        <thead>
          <tr>
            <th class="tx-left">#</th>
            <th class="tx-left">DID</th>
            <th class="tx-left">Short Name</th>
            <th class="tx-left">Expiry</th>
            <th class="tx-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-bind:key="index" v-for="(i, index) in issuers">
            <td class="masked">{{index+1}}</td>
            <did-link :did="i.did"></did-link>
              <td class="masked">
                  <did-name :did="i.did"></did-name>
              </td>
            <td class="masked">{{i.expiry | medium}}</td>
            <td>
              <span @click="setIssuerDidToRevoke(i.did)" class="text-danger delete" data-target="#controllerDeleteModal" data-toggle="modal" style="cursor: pointer">
                <i class="fas fa-trash-alt" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mg-t-50" v-else>
      <h4 class="card-title text-muted text-center">No records found</h4>
    </div>
      
    <div class="modal" id="controllerDeleteModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-body tx-center pd-y-20 pd-x-20">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <i class="icon ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block"></i>
            <h4 class="tx-indigo mg-b-20">Warning!</h4>
            <p class="mg-b-20 mg-x-20">Are you sure you want to remove this controller?</p>
            <button @click="revoke()" class="btn rounded btn-az-primary pd-x-25 mg-t-15" data-dismiss="modal" type="button">
              Continue
            </button>
          </div>
        </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import DidLink from '@/components/DidLink';
import DidName from "../../../../../common/DidName";

export default {
  name: "DidAuthorization",
  components: {DidName, DidLink },
  props: ['did'],
  data() {
    return {
      consumers: [],
      issuers: [],
      issuer_did_to_revoke: null,
      consumer_did_to_revoke: null,
      selected_permission: null,
    };
  },
  mounted() {
    this.getAuthorizations();
  },
  methods: {
    async getAuthorizations() {
      try {
        let { data } = await this.$identity.get(`identity/dids/${this.did}/authorizations`);
        this.consumers = data.consumers;
        this.issuers = data.issuers;
      } catch (e) {
        console.log(e);
        this.$toastr.e("Failed to get authorizations", "Error");

      }
    },
    setSelectedPermission(selected_permission) {
      this.selected_permission = selected_permission;
    },

    setIssuerDidToRevoke(did) {
      this.issuer_did_to_revoke = did;
    },
    setConsumerDidToRevoke(did) {
      this.consumer_did_to_revoke = did;
    },
    revoke() {
      if (this.issuer_did_to_revoke) {
        this.revokeIssuerAuthorization();
      } else if (this.consumer_did_to_revoke) {
        this.revokeConsumerAuthorization();
      }
    },
    async revokeIssuerAuthorization() {
      let req_body = {
        issuers: []
      };
      req_body.issuers.push(this.issuer_did_to_revoke);
      try {
        EventBus.$emit('openLoader');
        await this.$identity.delete(`/identity/dids/${this.did}/authorizations`, {
          data: req_body
        });
        this.issuer_did_to_revoke = null;
        await this.getAuthorizations();
        this.$toastr.s("Issuer removed successfully", "Success");
      } catch (e) {
        console.log(e);
        this.$toastr.e("Issuer remove failed", "Error");
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    async revokeConsumerAuthorization() {
      let req_body = {
        consumers: []
      };
      req_body.consumers.push(this.consumer_did_to_revoke);
      try {
        EventBus.$emit('openLoader');
        await this.$identity.delete(`/identity/dids/${this.did}/authorizations`, {
          data: req_body
        });
        this.consumer_did_to_revoke = null;
        await this.getAuthorizations();
        this.$toastr.s("Consumer removed successfully", "Success");
      } catch (e) {
        this.$toastr.e("Consumer remove failed", "Error");
      } finally {
        EventBus.$emit('closeLoader');
      }
    }
  }
}
</script>

<style scoped>
</style>