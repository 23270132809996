<template>
    <div>
        <div class="row row-sm mg-t-20 mg-b-20">
            <div class="col-9">
                <p v-html="$DEFINITIONS.didController"></p>
            </div>
            <div class="col-3">
                <router-link :to="{name: 'addcontrollers', params: { did: this.did }}" class="d-none d-xl-block btn btn-orange float-right rounded font-weight-bold">
                    <i class="fas fa-plus-square mg-r-10"/>Add Controller
                </router-link>
                <router-link :to="{name: 'addcontrollers', params: { did: this.did }}" class="d-xl-none btn btn-orange float-right rounded font-weight-bold">
                    <i class="typcn typcn-document-add"/>
                </router-link>
            </div>
        </div>

        <div class="table-responsive" v-if="controller_details.length >0">
            <table class="table table-striped mg-b-0">
                <thead>
                <tr>
                    <th class="tx-left">#</th>
                    <th class="tx-left">Name</th>
                    <th class="tx-left">Email</th>
                    <th class="tx-left">Web</th>
                    <th class="tx-left">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-bind:key="index" v-for="(controller, index) in controller_details">
                    <td class="masked">{{index+1}}</td>
                    <td>{{controller.name}}</td>
                    <td>{{controller.email}}</td>
                    <td>{{controller.web}}</td>
                    <td>
              <span @click="setControlerToRemove(controller.pub_key)" class="text-danger delete" data-target="#confirmationmodal" data-toggle="modal" style="cursor: pointer">
                <i class="fas fa-trash-alt"/>
              </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mg-t-50" v-else>
            <h4 class="card-title text-muted text-center">No records found</h4>
        </div>

        <div class="modal" id="confirmationmodal">
            <div class="modal-dialog" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-body tx-center pd-y-20 pd-x-20">
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">×</span>
                        </button>
                        <i class="icon ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block"></i>
                        <h4 class="tx-indigo mg-b-20">Warning!</h4>
                        <p class="mg-b-20 mg-x-20">Are you sure you want to remove this Controller?</p>
                        <button @click="removeController()" class="btn rounded btn-az-primary pd-x-25 mg-t-15" data-dismiss="modal" type="button">
                            Continue
                        </button>
                    </div>
                </div><!-- modal-content -->
            </div><!-- modal-dialog -->
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import EventBus from "@/event-bus";

    export default {
        name: "DidController",
        components: {Multiselect},
        props: ['controllers', 'did', 'catalogNameProp', 'short_nameProp'],
        watch: {
            controllers: function (nv, ov) {
                this.init();
            }
        },
        data() {
            return {
                controller_details: [],
                pub_key_to_remove: []
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await this.getControllerByPubKey();
            },
            async getControllerByPubKey() {
                try {
                    let {data} = await this.$accounts.post('/accounts/org/by_public_keys', {
                        pub_keys: this.controllers
                    });
                    this.controller_details = data.orgs;
                } catch (e) {

                }
            },
            setControlerToRemove(pub_key) {
                this.pub_key_to_remove.push(pub_key);
            },
            async removeController() {
                try {
                    EventBus.$emit('openLoader');
                    await this.$identity.patch(`/identity/dids/${this.did}/controllers`, {
                        remove: this.pub_key_to_remove
                    });
                    this.$toastr.s("Controller removed successfully", "Success");
                    this.pub_key_to_remove = [];
                    this.$emit("getDid");
                } catch (e) {
                    console.error(e)
                    this.$toastr.e("Controller remove failed", "Error");
                } finally {
                    EventBus.$emit('closeLoader');
                }
            },
        }
    }
</script>

<style scoped>
</style>