<template>
  <div>

    <div class="row row-sm mg-t-20 mg-b-20">
      <div class="col-9">
        <p v-html="$DEFINITIONS.didDocument"></p>
      </div>
      <div class="col-3">
        <router-link :to="{ name: 'addrecords', params: { did: this.did } }"
          class="d-none d-xl-block btn btn-orange float-right rounded font-weight-bold" title="Add DID">
          <i class="fas fa-plus-square mg-r-10" />Add Record
        </router-link>
        <router-link :to="{ name: 'addrecords', params: { did: this.did } }"
          class="d-xl-none w-50 btn btn-icon btn-orange float-right rounded" title="Add DID">
          <i class="typcn typcn-document-add" />
        </router-link>
      </div>
    </div>

    <data-table :api="api" :columns="columns" @ready="onReady" id="props_table" list-key="property_list"
      @resJson="resData"></data-table>

    <div class="modal" id="DeleteModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-body tx-center pd-y-20 pd-x-20">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <i class="icon ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block"></i>
            <h4 class="tx-indigo mg-b-20">Warning!</h4>
            <p class="mg-b-20 mg-x-20">Are you sure you want to remove the DID records?</p>
            <button @click="removeDidRecords()" class="btn rounded btn-az-primary pd-x-25 mg-t-15" data-dismiss="modal"
              type="button">
              Continue
            </button>
          </div>
        </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>
  </div>
</template>

<script>
import AddDidRecord from "./AddDidRecord";
import EventBus from "@/event-bus";

export default {
  name: "DidRecords",
  components: { AddDidRecord },
  props: ['catalogid', 'catalogNameProp', 'did', 'short_nameProp'],
  data() {
    return {
      catalogName: this.catalogNameProp,
      short_name: this.short_nameProp,
      api: `${this.$identity.defaults.baseURL}/identity/dids/${this.did}/properties`,
      columns: [
        { type: 'name' },
        { type: 'fact' },
        { type: 'private' },
        { type: 'created' },
        { type: 'extrinsic' },
        {
          type: 'action',
          defaultContent: `
          <div class="btn-group btn-icon-list">
            ${this.$StandardButtons.editButton('Edit Property')}
            ${this.$StandardButtons.deleteButton('#DeleteModal', 'Delete Property')}
          </div>`
        },
      ],
      checkedDidRecords: [],
      table: null,
      lastEditedRow: null
    };
  },
  methods: {
    resData(json) {
      this.$emit('records',json.total);
    },
    setDidRecordsToRemove(did_record) {
      this.checkedDidRecords = [];
      this.checkedDidRecords.push(did_record);
    },
    async removeDidRecords() {
      let _mapped_records = this.checkedDidRecords.map(d => ({
        name: d.name
      }));
      try {
        EventBus.$emit('openLoader');
        await this.$identity.patch(`/identity/dids/${this.did}`, {
          remove: _mapped_records
        });
        this.$toastr.s("DID records removed!", 'Success');
        this.checkedDidRecords = [];
        this.table.ajax.reload();
      } catch (e) {

      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    onReady(table) {
      function yesNoRadio(data, name) {
        return `<div class="d-inline">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="${name}" value="true" ${data === true ? 'checked' : ''}>
                              <label class="form-check-label">
                                Yes
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="${name}" value="false" ${data === false ? 'checked' : ''}>
                              <label class="form-check-label">
                                No
                              </label>
                            </div>
                            </div>
                        
                    `;
      }

      this.table = table;
      let self = this;

      // console.log(this.property_list);

      $(async function () {

        $('#props_table tbody').on('click', '.delete', function () {
          let entity = table.row($(this).parents('tr')).data();
          self.setDidRecordsToRemove(entity);
          table.ajax.reload();
        });

        $('#props_table tbody').on('click', '.edit', async function () {
          let tr = $(this).closest('tr');
          let cells = tr.children().toArray();
          let row = self.table.row(tr);
          let property = row.data();
          table.columns(3).visible(false);
          table.columns(4).visible(false);
          let nameTd = $(cells[0]);
          let nameInput = $(`<input class="editor" value="${property.name}"></input>`);
          nameTd.empty();
          nameTd.append(nameInput);
          let valueTd = $(cells[1]);
          let valueColumnDiv = $(`<div class="edit-fact-container"></div>`);
          let typeColumnSelect = $(`<select class="form-control fact-type" style="width:95px" placeholder="Type" value="${property.fact.type}">
                          <option ${property.fact.type === 'String' ? 'selected' : ''} value="String">Text</option>
                          <option ${['U8', 'U16', 'U32', 'U128'].includes(property.fact.type) ? 'selected' : ''} value="Number">Number</option>
                          <option ${property.fact.type === 'Bool' ? 'selected' : ''} value="Bool">Yes/No</option>
                          </select>`);

          function getValueInput() {
            let valueInput;
            if (typeColumnSelect.val() != 'Bool') {
              valueInput = $(`<input class="editor" value="${property.fact.value}"></input>`);
            } else {
              valueInput = $(yesNoRadio(property.fact.value, 'typeRadio'));
            }
            return valueInput;
          }

          let valueInput = getValueInput();
          typeColumnSelect.on('change', function () {
            valueInput.remove();
            valueInput = getValueInput();
            valueColumnDiv.append(valueInput);
          });
          valueColumnDiv.append(typeColumnSelect);
          valueColumnDiv.append(valueInput);
          valueTd.empty();
          valueTd.append($(valueColumnDiv));
          let privateTd = $(cells[2]);
          let privateInput = yesNoRadio(property.private, 'privateRadio');
          privateTd.empty();
          privateTd.append(privateInput);
          let saveBtn = $(`<button class="table-editor-button table-editor-check"><i class="fas fa-check"></i></button>`);
          let cancelBtn = $(`<button class="table-editor-button table-editor-cancel"><i class="fas fa-times"></i></button>`);
          let actionTd = $(cells[5]);
          if (actionTd.toArray().length == 0) {
            actionTd = $(cells[3]);
          }
          let editButton = actionTd.find('.edit');
          editButton.hide();
          let deleteButton = actionTd.find('.delete');
          deleteButton.hide();
          actionTd.prepend(cancelBtn);
          actionTd.prepend(saveBtn);
          actionTd.addClass('text-nowrap');
          saveBtn.on('click', async function (e) {
            e.stopPropagation();
            let value;
            if (typeColumnSelect.val() == 'Bool') {
              value = valueTd.find('input[name=typeRadio]:checked').val();
            } else {
              value = valueInput.val();
            }
            let _private = privateTd.find('input[name=typeRadio]:checked').val() == 'true';
            console.log(nameInput.val(), typeColumnSelect.val(), value, _private);
            await self.updateProperty(property.name, nameInput.val(), typeColumnSelect.val(), value, _private);

            nameTd.text(nameInput.val());
            valueTd.text(value);
            privateTd.text(_private ? 'Yes' : 'No');
            saveBtn.remove();
            cancelBtn.remove();
            editButton.show();
            deleteButton.show();
            if ($('#props_table tbody').find('.table-editor-button').toArray().length == 0) {
              table.columns(3).visible(true);
              table.columns(4).visible(true);
            }
          });
          cancelBtn.on('click', function (e) {
            e.stopPropagation();
            saveBtn.remove();
            cancelBtn.remove();
            nameTd.text(property.name);
            valueTd.text(property.fact.value);
            privateTd.text(property.private ? 'Yes' : 'No');
            saveBtn.remove();
            cancelBtn.remove();
            editButton.show();
            deleteButton.show();
            if ($('#props_table tbody').find('.table-editor-button').toArray().length == 0) {
              table.columns(3).visible(true);
              table.columns(4).visible(true);
            }
          });

        });

      });
    },
    async updateProperty(oldName, name, type, value, _private) {
      try {
        EventBus.$emit('openLoader');
        await this.$identity.patch(`/identity/dids/${this.did}`, {
          remove: [{ name: oldName }],
          add: [{
            name,
            private: _private,
            type: this.getDatatype(type, value),
            value
          }]
        });
        this.$toastr.s("DID records updated successfully", "Success");
      } catch (e) {
        console.log(e);
        this.$toastr.e("Failed to update DID", "Error");
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    getDatatype(type, val) {
      switch (type) {
        case "String":
          return 'String';
        case "Number":
          if (val >= 0 && val < 256) {
            return "U8";
          } else if (val > 255 && val < 65537) {
            return "U16";
          } else if (val > 65536 && val < 4000001) {
            return "U32";
          } else if (val) {
            return "U128";
          }
        case "Yes/No":
          return "Bool";

      }
    },
  }
}
</script>

<style>
div.slider {
  display: none;
}</style>